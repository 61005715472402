<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{ $t('labels.details') }}
      </b-card-title>
    </b-card-header>
    <b-card v-if='!isDataLoaded'>
      <div class='text-center text-primary my-2'>
        <b-spinner class='align-middle' />
      </div>
    </b-card>
    <hr class="my-0">

    <b-card-body v-else
      v-if="details"
      no-body
    >
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-sm-3">
              {{ $t('labels.name') }}
            </div>
            <div class="col-sm-9">
              {{ details.name }}
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-sm-3">
              {{ $t('labels.mobile') }}
            </div>
            <div class="col-sm-9">
              {{ details.mobile }}
            </div>
          </div>
        </div>

      </div>

      <hr>
<div class="row">
  <div class="col-12 col-md-6">
    <div class="row">
      <div class="col-sm-3">
        {{ $t('labels.email') }}
      </div>
      <div class="col-sm-9">
        {{ details.email }}
      </div>
    </div>
  </div>

</div>
      <hr>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-sm-3">
              {{ $t('labels.subject') }}
            </div>
            <div class="col-sm-9">
              {{ details.subject }}
            </div>
          </div>
        </div>

      </div>
      <hr>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-sm-3">
              {{ $t('labels.created_at') }}
            </div>
            <div class="col-sm-9">
              {{ details.created_at }}
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-sm-3">
              {{ $t('labels.message') }}
            </div>
            <div class="col-sm-9">
              {{ details.content }}
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div
        v-if="details.image"
        class="row"
      >
        <div class="col-12">
          <div class="row">
            <div class="col-sm-3">
              {{ $t('labels.image') }}
            </div>
            <div class="col-sm-12">
              <img
                style="width: 100%;height: 400px;object-fit: contain;"
                class="img-fluid personal-image"
                :src="details.image"
                alt="image"
              >
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
export default {
  data() {
    return {
      isDataLoaded:false,
      details: null,
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.axios.get(`/contact-uses/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
  },
}
</script>
